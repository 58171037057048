import {Col, Row} from "reactstrap";
import React from "react";
import FinanceImage from "./FinanceImage";

const FinanzmanagementFeaturette = ({imageTitle, imageText}) => (
    <Row className="featurette">
        <Col md="5">
            <FinanceImage/>
        </Col>
        <Col md="7" className="px-5">
            <div className="featurette-content">
                <div>
                    <h2  className="my-4">Finanzmanagement</h2>
                    <p>
                        Medoboard legt höchsten Wert auf einfache Bedienung: <br />
                        Mit nur einem Klick haben Sie alle wesentlichen Informationen rund um die Finanzen ihrer Ordination
                        im Blick:
                    </p>
                    <ul>
                        <li>Zahlweise</li>
                        <li>Status</li>
                        <li>Betrag</li>
                        <li>Umsatz pro Monat</li>
                        <li>und vieles mehr</li>
                    </ul>
                </div>
            </div>
        </Col>
    </Row>
)

export default FinanzmanagementFeaturette;
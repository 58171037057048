import React from 'react'
import {Container, Row, Col} from 'reactstrap';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {withPrefix} from 'gatsby';
import {Pricing} from '../components/pricing';
import FinanzmanagementFeaturette from '../components/FinanzmanagementFeaturette';
import FinanzManagementImage from '../components/FinanzManagementImage';

const Finanzmanagement = (props) => (
    <Layout>
        <SEO googleTitle="Medoboard - optimiert Ihre Praxis"
             googleDescription="Medoboard organisiert Ihre Ordination mit intelligenten Funktionalitäten und optimiert Ihre Praxis."
             fbUrl="https://medoboard.com/finanzmanagement"
             fbImage={withPrefix('medoboard-fb.png')}
             fbTitle="Medoboard - optimiert Ihre Praxis"
             fbDescription="Medoboard unterstützt Sie in ihrer täglichen Arbeit mit dem Patienten damit Sie mehr Zeit für das Wesentliche haben"
             keywords={[`medoboard`, `Ordinationssoftware`, `intelligent`, `einfach`, `Patientenakte`, `Arztsoftware`]}/>
        <Container>
            <Row className="carousel">
                <Col md="12">
                    <FinanzManagementImage/>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <h2 className="featurette-heading">Ihre Finanzen. Alles auf einen Blick.</h2>
                    <h4 className="text-muted mb-5">Medoboard wurde gemeinsam mit Ärzten entwickelt um Ihre Anforderungen direkt umzusetzen.</h4>
                    <p className="lead">
                        Medoboard bietet Ihnen eine einfache und schnelle Honorarnoten-Erstellung:
                    </p>
                    <ul className="lead">
                        <li>Honorarnoten können einfach mit vordefinierten Werten befüllt werden</li>
                        <li>Intelligente Vorlagen helfen bei der effizienten und sicheren Honorarnotenerstellung</li>
                        <li>Auf einen Klick haben Sie den aktuellen Status der letzten Zahlungen im Blick</li>
                    </ul>
                </Col>
            </Row>
            <hr className="featurette-divider"/>
            <Row>
                <Col md="12">
                    <FinanzmanagementFeaturette imageTitle="Finanzmanagement"/>
                </Col>
            </Row>
            <hr className="featurette-divider"/>
            <Pricing/>
        </Container>
    </Layout>
)

export default Finanzmanagement;